import { graphql } from "gatsby";
import React, { useCallback, useState } from "react";
import { Layout } from "../components/Layout";
import { SelfCheckoutHero } from "../components/SelfCheckoutHero";
import Helmet from "react-helmet";
import { SelfCheckoutCompletedProjects } from "../components/SelfCheckoutCompletedProjects";
import { SelfCheckoutAdvantages } from "../components/SelfCheckoutAdvantages/SelfCheckoutAdvantages";
import { ContactUsModal } from "../components/ContactUsModal";

const SelfCheckoutTemplate = ({ layout, ...props }) => {
  const [isOpenContactUsModal, setIsOpenContactUsModal] = useState();
  const handleOpenContactUsModal = useCallback(() => setIsOpenContactUsModal(true), []);
  const handleCloseContactUsModal = useCallback(() => setIsOpenContactUsModal(false), []);
  return (
    <Layout {...layout} lang={props.layoutId} onOpenContactUsModal={setIsOpenContactUsModal}>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>
      <SelfCheckoutHero {...props.selfCheckoutHero} onOpenContactUsModal={handleOpenContactUsModal} />
      <SelfCheckoutAdvantages {...props.selfCheckoutAdvantages} />
      <SelfCheckoutCompletedProjects {...props.selfCheckoutCompletedProjects} />

      <ContactUsModal isOpen={isOpenContactUsModal} onClose={handleCloseContactUsModal} {...layout.contactUsModal} />
    </Layout>
  );
};

SelfCheckoutTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...SelfCheckoutHero.getLivePreviewData(props),
  ...SelfCheckoutTemplate.getLivePreviewData(props),
});

const SelfCheckoutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return <SelfCheckoutTemplate layout={data.layout.frontmatter} {...frontmatter} />;
};

const pageQuery = graphql`
  query SelfCheckoutTemplate($id: String!, $layoutId: String!) {
    layout: markdownRemark(frontmatter: { id: { eq: $layoutId } }) {
      frontmatter {
        ...LayoutFragment
        ...ContactUsModalFragment
      }
    }

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        layoutId
        seo {
          title
          description
          keywords
        }

        ...SelfCheckoutHeroFragment
        ...SelfCheckoutCompletedProjectsFragment
        ...SelfCheckoutAdvantagesFragment
      }
    }
  }
`;

export default SelfCheckoutPage;

export { pageQuery, SelfCheckoutTemplate };
