import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Button } from "../Button";
import "./styles.css";

const SelfCheckoutHero = ({ title, text, buttonLabel, supTitle, background, onOpenContactUsModal, features }) => {
  return (
    <div className="SelfCheckoutShadow">
      <div className="[ SelfChechoutHero ] [ Content ]">
        <div className="SelfChechoutHero__content">
          <h1 className="Font_smallHeader sm:Font_mediumTextBold Color_middleGrey">{supTitle}</h1>
          <h2 className="[ SelfChechoutHero__title ] [ Font_superHeader sm:Font_smallHeader ]">{title}</h2>
          <p className="[ SelfChechoutHero__text ] [ Font_bigText sm:Font_regularText Color_darkGrey ]">{text}</p>
          <ul className="[ SelfChechoutHero__features ] ">
            {features.map(feature => (
              <li key={feature} className="[ Font_mediumText sm:Font_regularText Color_darkGrey ]">
                {feature}
              </li>
            ))}
          </ul>
          <Button className="SelfCheckoutHero__Button" onClick={onOpenContactUsModal}>
            {buttonLabel}
          </Button>
        </div>
        <div className="SelfChechoutHero__imageContainer">
          <Img
            className="SelfChechoutHero__image"
            fluid={[
              background.childImageSharp.fluid,
              { ...background.childImageSharp.fluid, media: "(max-width: 699px)" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

SelfCheckoutHero.getLivePreviewData = ({ data, getAsset }) => ({
  hero: {
    ...data.hero,
    background: getAsset(data.hero.background).url,
  },
});

const selfCheckoutHeroQuery = graphql`
  fragment SelfCheckoutHeroFragment on MarkdownRemarkFrontmatter {
    selfCheckoutHero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      title
      text
      features
      supTitle
      buttonLabel
    }
  }
`;

export { SelfCheckoutHero, selfCheckoutHeroQuery };
