import { graphql } from "gatsby";
import React from "react";
import { CompletedProjects } from "../CompletedProjects/CompletedProjects";
import "./styles.css";

const SelfCheckoutCompletedProjects = ({ title, examples }) => {
  return (
    <div id="completed-projects">
      <CompletedProjects title={title} examples={examples} />
    </div>
  );
};

SelfCheckoutCompletedProjects.getLivePreviewData = ({ data, getAsset }) => ({
  selfCheckoutCompletedProjects: {
    ...data.selfCheckoutCompletedProjects,
    examples: data.selfCheckoutCompletedProjects.examples.map(example => ({
      ...example,
      photo: getAsset(example.photo).url,
    })),
  },
});

const selfCheckoutCompletedProjectsQuery = graphql`
  fragment SelfCheckoutCompletedProjectsFragment on MarkdownRemarkFrontmatter {
    selfCheckoutCompletedProjects {
      title
      examples {
        photo {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        description
      }
    }
  }
`;

export { SelfCheckoutCompletedProjects, selfCheckoutCompletedProjectsQuery };
