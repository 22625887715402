import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import selfCheckoutAdvantagesLeft from "./images/selfCheckoutAdvantagesLeft.svg";
import selfCheckoutAdvantagesRight from "./images/selfCheckoutAdvantagesRight.svg";
import "./styles.css";

const SelfCheckoutAdvantages = ({ title, text, subText, percent, image, advantages }) => {
  return (
    <section className="SelfCheckoutAdvantages">
      <img
        src={selfCheckoutAdvantagesLeft}
        alt=""
        className="SelfCheckoutAdvantages__background SelfCheckoutAdvantages__background_left"
      />
      <img
        src={selfCheckoutAdvantagesRight}
        alt=""
        className="SelfCheckoutAdvantages__background SelfCheckoutAdvantages__background_right"
      />

      <div className="[ SelfCheckoutAdvantages__fullContent ] [ Content ]">
        <div className="SelfChecoutAdvantages__image">
          <Img fluid={[image.childImageSharp.fluid, { ...image.childImageSharp.fluid, media: "(max-wdth: 398px)" }]} />
        </div>
        <div className="SelfCheckoutAdvantages__content">
          <h1 className="Font_header sm:Font_smallHeader">{title}</h1>
          <p className="[ SelfCheckoutAdvantages__text ] [ Font_bigText sm:Font_regularText ]">{text}</p>
          <ul className="[ SelfCheckoutAdvantages__features ] [ Font_bigText sm:Font_regularText ]">
            {advantages.map(advantage => (
              <li key={advantage}>{advantage}</li>
            ))}
          </ul>
          <div className="SelfCheckoutAdvantages__subText">
            <p className="[ Color_middleGrey ] [ Font_mediumText20 ]">{subText}</p>
            <p className="Font_megaHeader">{percent}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

SelfCheckoutAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  advantages: {
    ...data.advantages,
    image: getAsset(data.advantages.image).url,
  },
});

const selfCheckoutAdvantagesQuery = graphql`
  fragment SelfCheckoutAdvantagesFragment on MarkdownRemarkFrontmatter {
    selfCheckoutAdvantages {
      image {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      title
      text
      subText
      percent
      advantages
    }
  }
`;

export { SelfCheckoutAdvantages, selfCheckoutAdvantagesQuery };
